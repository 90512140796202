import { HashRouter as Router, Routes, Route } from "react-router-dom";
import TitlePage from "./Pages/TitlePage";
import AdminPortal from "./Pages/admin_portal";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/Term&Conditions";
import Careers from "./Pages/Careers";
import NoDealsYet from "./Pages/NoDealsYet";
import Coupons from "./Pages/Coupons";
import Shopcoupon from "./Pages/ShopCoupons";
import Learn from "./Pages/Learn";
import Construction from "./Pages/underConstruction";
import Edit from "./Pages/Edit";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TitlePage />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/AdminPortal" element={<AdminPortal />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms&Conditions" element={<TermsAndConditions />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/NoDealsYet" element={<NoDealsYet />} />
        <Route path="/Coupons" element={<Coupons />} />
        <Route path="/ShopCoupons/:shopName" element={<Shopcoupon />} />
        <Route path="/Learn" element={<Learn />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/underConstruction" element={<Construction />} />
      </Routes>
    </Router>
  );
}

export default App;
